

















import { Component, Prop, Vue } from 'vue-property-decorator'

import { Inject, logger } from '../../../../../support'

import { IProductsRepository, ProductsRepositoryType } from '../../../contracts/repositories'
import { ProductData } from '../../../../../contexts'
import {
  translateToCartItemEnhanced
} from '../../../../checkout/molecules/CartItem/CartItem.helpers'
import { CartItemProps } from '../../../../checkout/molecules/CartItem/CartItem.contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<GiftBox>({
  name: 'GiftBox',
  created () {
    this.loadGift()
  }
})
export class GiftBox extends Vue {
  @Inject(ProductsRepositoryType)
  protected readonly productsRepository!: IProductsRepository

  @Prop({ type: Object, required: true })
  public readonly product!: ProductData

  public isLoading: boolean = false
  public gift: CartItemProps | null = null

  protected async loadGift (): Promise<void> {
    if (!this.product) {
      return
    }

    this.isLoading = true

    const gifts = []

    for (const variant of Object.values(this.product.variants)) {
      if (variant.attributes.giftsSku && (variant.attributes.giftsSku as Array<string>).length > 0) {
        for (const gift of Object.values(variant.attributes.giftsSku)) {
          gifts.push(gift)
        }
      }
    }

    try {
      if (gifts.length > 0) {
        const gift = await this.productsRepository.loadProductsBySkus(gifts.slice(0, 1))

        if (gift) {
          this.gift = translateToCartItemEnhanced(gift[0], [], 100)
        }
      }
    } catch (e) {
      logger(e, 'warn')
    } finally {
      this.isLoading = false
    }
  }
}

export default GiftBox

import { Component, Mixins, Watch } from 'vue-property-decorator'

import { AllowedAttributes } from '../../../contexts'
import { IToastOptions } from '../../../backoffice/shared/services/toaster'
import { logger } from '../../../support'

import { BaseCartMixin } from '../../checkout/shared/mixins/base-cart.mixin'
import { CartModel, CartMutationTypes } from '../../checkout/contracts'

import { BundledItemProps } from '../molecules/BundledItem'
import { ToastMixin } from './toast.mixin'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<PresaleMixin>({
  name: 'PresaleMixin',
  beforeDestroy () {
    this.$store.commit(CartMutationTypes.SetPresaleCartItemsPresent, false)
    this.$store.commit(CartMutationTypes.SetOnlyRegularCartItemsPresent, false)
    this.$store.commit(CartMutationTypes.SetIsBundlePresale, false)
    this.$store.commit(CartMutationTypes.SetIsBundlePresaleInCartItems, false)
  }
})
export class PresaleMixin extends Mixins(BaseCartMixin, ToastMixin) {
  private DEFAULT_TOAST_DURATION = 6500

  @Watch('onlyRegularCartItemsPresent')
  protected triggerRegularItemsPresaleWarning () {
    if (this.onlyRegularCartItemsPresent && this.toastService) {
      this.showToast(
        this.$t('front.products.organisms.productHeader.presaleTimer.warnings.ifCartItemsPresent').toString(),
        'danger',
        '',
        {
          ...this.toastDefaultOptionsConfig,
          duration: this.DEFAULT_TOAST_DURATION,
          dismissible: true
        }
      )

      setTimeout(() => {
        this.$store.commit(CartMutationTypes.SetOnlyRegularCartItemsPresent, false)
      }, 0)
    }
  }

  @Watch('presaleCartItemsPresent')
  protected triggerPresaleItemsPresaleWarning () {
    if (this.presaleCartItemsPresent && this.toastService) {
      this.showToast(
        this.$t('front.products.organisms.productHeader.presaleTimer.warnings.ifPresaleInCart').toString(),
        'danger',
        '',
        {
          ...this.toastDefaultOptionsConfig,
          duration: this.DEFAULT_TOAST_DURATION,
          dismissible: true
        }
      )

      setTimeout(() => {
        this.$store.commit(CartMutationTypes.SetPresaleCartItemsPresent, false)
      }, 0)
    }
  }

  @Watch('isBundlePresale')
  protected triggerBundlePresaleWarning () {
    if (this.isBundlePresale && this.toastService) {
      this.showToast(
        this.$t('front.products.organisms.productHeader.presaleTimer.warnings.ifBundleIsPresale').toString(),
        'danger',
        '',
        {
          ...this.toastDefaultOptionsConfig,
          duration: this.DEFAULT_TOAST_DURATION,
          dismissible: true
        }
      )

      setTimeout(() => {
        this.$store.commit(CartMutationTypes.SetIsBundlePresale, false)
      }, 0)
    }
  }

  @Watch('isPresaleBundleInCartItems')
  protected triggerPresaleBundleInCartItemsWarning () {
    if (this.isPresaleBundleInCartItems && this.toastService) {
      this.showToast(
        this.$t('front.products.organisms.productHeader.presaleTimer.warnings.ifBundleCartItemIsPresale').toString(),
        'danger',
        '',
        {
          ...this.toastDefaultOptionsConfig,
          duration: this.DEFAULT_TOAST_DURATION,
          dismissible: true
        }
      )

      setTimeout(() => {
        this.$store.commit(CartMutationTypes.SetIsBundlePresaleInCartItems, false)
      }, 0)
    }
  }

  public get toastDefaultOptionsConfig (): IToastOptions {
    return this.toastService!.getOptionsConfig()
  }

  public get presaleCartItemsPresent (): boolean {
    return this.$store.getters['checkout/presaleCartItemsPresent']
  }

  public get onlyRegularCartItemsPresent (): boolean {
    return this.$store.getters['checkout/onlyRegularCartItemsPresent']
  }

  public get isBundlePresale (): boolean {
    return this.$store.getters['checkout/isBundlePresale']
  }

  public get isPresaleBundleInCartItems (): boolean {
    return this.$store.getters['checkout/isPresaleBundleInCartItems']
  }

  public isBundleInPresale (bundles: Array<Omit<BundledItemProps, 'uid'>>): boolean {
    if (bundles.length === 0) {
      this.$store.commit(CartMutationTypes.SetIsBundlePresale, false)
      return false
    }

    const presaleCartItems = this.cart?.items.find((item) => Object.values(item.product.variants)[0].attributes[AllowedAttributes.IsPresale])
    if (presaleCartItems) {
      this.$store.commit(CartMutationTypes.SetIsBundlePresaleInCartItems, !!presaleCartItems)
      return !!presaleCartItems
    }

    const isBundlePresale = bundles.find(product => Object.values(product.product.variants)[0].attributes[AllowedAttributes.IsPresale])
    this.$store.commit(CartMutationTypes.SetIsBundlePresale, !!isBundlePresale)

    return !!isBundlePresale
  }

  /**
   * Checks whether user can add presale item to the cart. Business conditions:
   * -- presale item can not be added if cart has other presale item
   * -- presale item can not be added if cart length > 0 and neither item is presale
   * @protected
   */
  protected async canAddPresale (isRequestedPresale: boolean, sku: string): Promise<boolean> {
    try {
      if (this.cartId === null) {
        this.isLoggedInUser ? await this.createCustomerCart() : await this.createCart()
      }

      if (!this.cart) {
        return false
      }

      if (this.isSameProduct(this.cart, sku)) {
        return true
      }

      const isPresaleItemInCart = PresaleMixin.isPresaleItemInCart(this.cart)
      this.$store.commit(CartMutationTypes.SetPresaleCartItemsPresent, isPresaleItemInCart)

      if (!isRequestedPresale) {
        return !isPresaleItemInCart
      }

      this.$store.commit(CartMutationTypes.SetOnlyRegularCartItemsPresent, this.cart ? (this.cart.items.length > 0 && !isPresaleItemInCart) : false)

      return !(this.presaleCartItemsPresent || this.onlyRegularCartItemsPresent)
    } catch (e) {
      logger(e, 'warn')
      return false
    }
  }

  /**
   * Checks if cart contains at least one presale product
   * @private
   */
  private static isPresaleItemInCart (cart: CartModel): boolean {
    return cart.items.some((item) => item.isPresale)
  }

  /**
   * Checks if cart contains the same presale product that user wants to add again
   * @private
   */
  private isSameProduct (cart: CartModel, sku: string): boolean {
    return !!cart.items.find((item) => Object.values(item.product.variants)[0].sku === sku)
  }
}





























import { Component, Prop, Vue } from 'vue-property-decorator'
import { EventbusType, EventPayload, IEventbus } from '@movecloser/front-core'

import { StarsRateProps } from '../../../../../dsl/molecules/StarsRate'
import Loader from '../../../../shared/molecules/Loader/Loader.vue'
import { Inject } from '../../../../../support'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<VariantDetailsRating>({
  name: 'VariantDetailsRating',
  components: {
    Loader
  },
  mounted () {
    this.eventBus.handle('product:trustedShop-global-loading', (event: EventPayload<boolean>) => {
      this.isLoading = !!event.payload
    })
  }
})
export class VariantDetailsRating extends Vue {
  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Prop({ type: Number, required: true })
  public readonly ratingAmount!: number

  @Prop({ type: Boolean, required: true })
  public readonly shouldHaveVisibleRating!: boolean

  @Prop({ type: Object, required: true })
  public readonly starsRateProps!: Omit<StarsRateProps, 'model'>

  @Prop({ type: Number, required: false })
  public readonly starsRateModel?: Pick<StarsRateProps, 'model'>

  @Prop({ type: String, required: true })
  public readonly productReviewsIdentifier!: string

  @Prop({ type: Boolean, required: true })
  public readonly shouldHaveSeparatedRating!: boolean

  @Prop({ type: String, required: true })
  public readonly variantRating!: string

  @Prop({ type: String, required: true })
  public readonly defaultMaxRating!: string

  @Prop({ type: String, required: true })
  public readonly rating!: string

  public isLoading: boolean = false
}

export default VariantDetailsRating

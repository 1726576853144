




















































import { Component, Inject, Mixins, Prop, Watch } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'

import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../support'

import { BadgeProps } from '../../../../dsl/atoms/Badge'
import { CarouselProps } from '../../../../dsl/molecules/Carousel'
import { ImageProps } from '../../../../dsl/atoms/Image'
import { StructureConfigurable } from '../../../../support/mixins'

import { generateImagePlaceholder } from '../../support/image-placeholder'

import { CustomImageZoom, CustomThumbnails } from './partials'
import { GALLERY_COMPONENT_CONFIG_MAP, GALLERY_COMPONENT_KEY } from './Gallery.config'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component<Gallery>({
  name: 'Gallery',
  components: { CustomThumbnails, CustomImageZoom },
  created () {
    this.config = this.getComponentConfig(GALLERY_COMPONENT_KEY, GALLERY_COMPONENT_CONFIG_MAP)
  }
})
export class Gallery extends Mixins(StructureConfigurable) {
  @Prop({ required: false, type: Array })
  public readonly badges!: BadgeProps[]

  @Prop({ required: true, type: Array })
  public readonly fullImages!: ImageProps[]

  @Prop({ required: true, type: Object })
  public readonly mobileCarousel!: CarouselProps

  @Prop({ required: true, type: Array })
  public readonly previewImages!: ImageProps[]

  @Prop({ required: true, type: Array })
  public readonly thumbnails!: ImageProps[]

  @Prop({ required: false, type: Object })
  public readonly thumbnailsCarouselConfiguration?: AnyObject

  @Inject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  public isActiveFullScreenMobile: boolean = false
  public selectedImageIndex: number = 0

  public get closeIcon (): string {
    return this.getConfigProperty<string>('closeIcon')
  }

  public get fullImage () {
    return this.fullImages[this.selectedImageIndex]
  }

  public get fallbackImage () {
    return generateImagePlaceholder('small').src
  }

  public activateFullScreenMobile () {
    this.isActiveFullScreenMobile = true
  }

  public deactivateFullScreenMobile () {
    this.isActiveFullScreenMobile = false
  }

  public onThumbnailChange (index: number) {
    this.selectedImageIndex = index
  }

  public onSlideChange (activeSlideIndex: number) {
    this.selectedImageIndex = activeSlideIndex
  }

  public onSlideClick () {
    this.activateFullScreenMobile()
  }

  @Watch('isActiveFullScreenMobile')
  public onActiveFullScreenMobileChange (newValue: boolean) {
    if (this.isMobile()) {
      if (newValue) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflowY = 'scroll'
      }
    }
  }
}

export default Gallery

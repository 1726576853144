

















import { Component, Inject as VueInject, Mixins, Prop, Ref } from 'vue-property-decorator'

import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../../support'

import TopBarMixin from '../../../../shared/mixins/topBar.mixin'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<PresaleTimer>({
  name: 'PresaleTimer',
  mounted (): void {
    this.init()

    if (this.showTimer) {
      this.calculateCountdown()

      this.interval = setInterval(() => {
        this.calculateCountdown()
      }, 60000)

      this.$nextTick(this.setHeight)
    }
  },
  destroyed (): void {
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = null
    }
  }
})
export class PresaleTimer extends Mixins<TopBarMixin>(TopBarMixin) {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Prop({ type: String, required: true })
  public presaleEndDate!: string

  @Ref('wrapper')
  public readonly wrapperRef?: HTMLDivElement

  public interval?: ReturnType<typeof setInterval> | null
  public presaleCountdown: number | null = null
  public presaleEndTime: number | null = null
  public today: number | null = null

  public get preorderLabel () {
    const daysLabel = this.$tc('front.products.organisms.productHeader.presaleTimer.days',
      this.presaleCountdown ?? 0).toString()

    return {
      days: daysLabel,
      count: this.presaleCountdown
    }
  }

  public get showTimer (): boolean {
    if (!this.presaleEndTime || !this.today) {
      return false
    }

    return this.today < this.presaleEndTime
  }

  public calculateCountdown (): void {
    if (!this.presaleEndTime || !this.today) {
      return
    }

    const remainingTime = (this.presaleEndTime - this.today) / 1000

    this.presaleCountdown = Math.floor(remainingTime / 60 / 60 / 24)
  }

  public init (): void {
    const now = new Date()
    this.today = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime()
    this.presaleEndTime = new Date(this.presaleEndDate).getTime()
  }

  private setHeight (): void {
    const root: HTMLElement = document.documentElement
    if (!root) {
      return
    }
    const height = this.wrapperRef ? this.wrapperRef.offsetHeight : 0

    root.style.setProperty('--product-top-bar-height', `${height}px`)
  }
}

export default PresaleTimer
